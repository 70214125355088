import { RegistryTilesContainer } from '~/components/common/RegistryTiles/RegistryTilesContainer';
import { useRegistryActions } from '~/hooks/useRegistryActions';
import { getManageRegistryCollections } from '~/selectors/manageRegistrySelectors';
import { getDefaultGiftCard } from '~/selectors/productSelectors';
import { useAppSelector } from '~/store';

import { PublicRegistryTiles } from './PublicRegistryTiles';
import { usePublicRegistry } from '../contexts/PublicRegistryContext';

export const PublicRegistryCollection = () => {
  const collections = useAppSelector(getManageRegistryCollections);
  const zolaGiftCard = useAppSelector(getDefaultGiftCard);
  const { registryKey, registryOverview, giftCardData } = usePublicRegistry();
  const { default_gift_card_enabled: isZolaGiftCardEnabled } = registryOverview || {};
  const { handleItemClick, handleProductClick } = useRegistryActions(registryKey);

  return (
    <RegistryTilesContainer>
      <PublicRegistryTiles
        items={collections}
        zolaGiftCard={isZolaGiftCardEnabled ? zolaGiftCard || giftCardData : null}
        onItemClick={handleItemClick}
        openItemDetail={handleProductClick}
      />
    </RegistryTilesContainer>
  );
};
