import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';

import styled from '@emotion/styled';

export const ShipmentContainer = styled.div`
  font-size: ${FONT.FONT_SIZE_SMALLER};
  color: ${COLORS3.BLACK_050};
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;
