import type { WRegistryCollectionItemView } from '@zola/svc-web-api-ts-client';

import {
  TileContainer,
  TileBody,
  BrandName,
  ItemName,
} from '~/components/common/RegistryTiles/RegistryTile.styles';
import { RegistryTileContributions } from '~/components/common/RegistryTiles/RegistryTileContributions';
import { RegistryTileImage } from '~/components/common/RegistryTiles/RegistryTileImage';
import { RegistryTilePrice } from '~/components/common/RegistryTiles/RegistryTilePrice';
import { BottomSection } from '~/components/common/RegistryTiles/RegistryTilePrice.styles';
import { getBrandName, isItemAvailable } from '~/components/common/RegistryTiles/utils';

import { PublicRegistryTileActions } from './PublicRegistryTileActions';
import { RegistryTileShipment } from './RegistryTileShipment';

interface PublicRegistryTileProps extends React.HTMLAttributes<HTMLDivElement> {
  item?: WRegistryCollectionItemView;
  onItemClick: (item: WRegistryCollectionItemView, qty: number) => void;
  openItemDetail: (item: WRegistryCollectionItemView) => void;
}

export const PublicRegistryTile: React.FC<PublicRegistryTileProps> = ({
  item,
  onItemClick,
  openItemDetail,
  ...rest
}) => {
  if (!item) return null;

  const brandName = getBrandName(item);
  const isAvailable = isItemAvailable(item);

  return (
    <TileContainer isActive={false} onClick={() => openItemDetail(item)} {...rest}>
      <RegistryTileImage editable={false} item={item} />
      <TileBody>
        <BrandName>{brandName}</BrandName>
        <ItemName>{item.name}</ItemName>
        <RegistryTilePrice item={item} />
        <RegistryTileShipment item={item} />
        <BottomSection>
          {isAvailable && <PublicRegistryTileActions item={item} onItemClick={onItemClick} />}
          {isAvailable && <RegistryTileContributions contributions={item.contributions} />}
        </BottomSection>
      </TileBody>
    </TileContainer>
  );
};
