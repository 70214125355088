import { useState } from 'react';

import type { WRegistryCollectionItemView } from '@zola/svc-web-api-ts-client';
import InputFieldV3 from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3';

import { Actions, TileButton } from './PublicRegistryTileActions.style';

interface PublicRegistryTileActionsProps extends React.HTMLAttributes<HTMLDivElement> {
  item?: WRegistryCollectionItemView;
  onItemClick: (item: WRegistryCollectionItemView, qty: number) => void;
}

export const PublicRegistryTileActions: React.FC<PublicRegistryTileActionsProps> = ({
  item,
  onItemClick,
}) => {
  const [qty, setQty] = useState(1);

  if (!item || !item.actions?.add_to_cart) return null;
  const isCashFund = item.cash_fund;
  // @ts-expect-error Update svc-web-api type export rule
  const isExternal = item.type === 'EXTERNAL';
  const isGroupGift = item.contributions?.group_gift;

  const hideQuantity = (isCashFund && !isExternal) || (!isCashFund && isExternal) || isGroupGift;

  return (
    <Actions
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {!hideQuantity && (
        <InputFieldV3
          inline
          value={qty.toString()}
          style={{ width: '44px', margin: '-4px 12px 0 0', textAlign: 'center' }}
          onChange={(event) => setQty(parseInt(event.currentTarget.value, 10))}
        />
      )}
      <TileButton onClick={() => onItemClick(item, qty)}>{item.button_cta}</TileButton>
    </Actions>
  );
};
