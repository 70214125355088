import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import InputFieldV3 from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';

import styled from '@emotion/styled';

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const StyledInputFieldV3 = styled(InputFieldV3)`
  width: 44px;
  margin: -4px 12px 0 0;
  text-align: center;
`;

export const TileButton = styled(ButtonV3)`
  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    width: 100%;
    flex-basis: 100%;
  }
`;
