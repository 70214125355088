/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';

import { getPublicRegistryNav } from '@zola-helpers/client/dist/es/nav/getNav';
import * as toastsActions from '@zola-helpers/client/dist/es/redux/toasts/toastsActions';

import dynamic from 'next/dynamic';

import { Container } from './PublicRegistryNav.styles';
import { useAppDispatch } from '../../../../store';

const UnifiedNavHtmlLoader = dynamic(
  () => import('~/components/common/header/UnifiedNavHtmlLoader'),
  { ssr: false }
);

export type PublicRegistryNavProps = {
  className?: string;
  // For client-side rendering, html should be null
  html: string | null;
};

const PublicRegistryNav = ({
  className,
  html: initialHtml,
}: PublicRegistryNavProps): JSX.Element => {
  const [html, setHtml] = useState<string | null>(initialHtml);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!html) {
      getPublicRegistryNav()
        .then((nav) => {
          setHtml(nav);
        })
        .catch(() => {
          dispatch(toastsActions.negative({ headline: 'Error occurred: please refresh the page' }));
        });
    }
  }, [dispatch, html]);

  const isClientSideHtml = !initialHtml && html;

  return (
    <Container className={className}>
      {isClientSideHtml && <UnifiedNavHtmlLoader html={html} />}
      {initialHtml && (
        <div
          id="root-nav-container"
          data-testid="root-nav"
          dangerouslySetInnerHTML={{ __html: initialHtml }}
        />
      )}
      <div id="notification-root" data-testid="notification-root" />
    </Container>
  );
};

export default PublicRegistryNav;
