import COLORS3, { COLORS_GRAYS } from '@zola/zola-ui/src/styles/emotion/colors3';
import MEDIA_QUERY, { SCREEN_XL } from '@zola/zola-ui/src/styles/emotion/mediaQuery';
import ZINDEX from '@zola/zola-ui/src/styles/emotion/zIndex';

import styled from '@emotion/styled';

const borderStyling = (): string => `1px solid ${COLORS_GRAYS.BLACK_030}`;

export const Container = styled.div`
  background-color: ${COLORS3.WHITE_100};
  margin: 60px auto 0;
  max-width: ${SCREEN_XL}px;
  padding: 0 40px 40px;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    padding: 16px 20px 20px;
  }

  ${MEDIA_QUERY.DESKTOP_XL} {
    border-left: ${borderStyling()};
    border-right: ${borderStyling()};
  }
`;

export const Header = styled.header`
  margin: 0 auto;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${ZINDEX.top};

  ${MEDIA_QUERY.DESKTOP_XL} {
    border-left: ${borderStyling()};
    border-right: ${borderStyling()};
    left: calc((100% - ${SCREEN_XL}px) / 2);
    width: ${SCREEN_XL}px;
  }
`;

export const FooterContainer = styled.div`
  margin: 0 auto;
  max-width: ${SCREEN_XL}px;

  ${MEDIA_QUERY.DESKTOP_XL} {
    border-left: ${borderStyling()};
    border-right: ${borderStyling()};
    border-bottom: ${borderStyling()};
  }
`;

export const FiltersContainer = styled.div``;
export const ProductGridContainer = styled.div``;
